<template>
  <div>
    <div v-if="displayMode==='dropdown' && currentLocale">
      <b-nav-item-dropdown
        id="dropdown-grouped"
        variant="link"
        class="dropdown-language"
        right
      >
        <template #button-content>
          <b-img
            :src="currentLocale.img"
            height="14px"
            width="22px"
            :alt="currentLocale.locale"
          />
          <span class="ml-50 text-body">{{ currentLocale.text }}</span>
        </template>
        <b-dropdown-item
          v-for="localeObj in locales"
          :key="localeObj.value"
          @click="setLocale(localeObj.value)"
        >
          <b-img
            :src="localeObj.img"
            height="14px"
            width="22px"
            :alt="localeObj.locale"
          />
          <span class="ml-50">
            {{ localeObj.text }}
          </span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </div>
    <div v-if="displayMode==='checkbox'">
      <b-form-group :label="$t('user-menu.available-options')">
        <b-form-radio-group
          id="locale-radio-group"
          v-model="selectedLocale"
          :options="localeNames"
          name="language"
          class="my-1"
          stacked
          @change="setLocale(selectedLocale)"
        />
      </b-form-group>
    </div>
    <div v-if="displayMode==='select'">
      <b-form-group :label="hasLabel?$t('preferred-language'):''">
        <v-select
          id="template"
          v-model="selectedLocale"
          :options="localeNamesArray"
          :reduce="(template) => template.key"
          label="language"
          :clearable="false"
          :placeholder="$t('empty-layout.template-placeholder')"
          @input="$emit('newLocale', selectedLocale)"
        />
      </b-form-group>
    </div>
  </div>
</template>

<script>
// import {
//   BNavItemDropdown, BDropdownItem, BImg, BFormRadioGroup, BFormGroup,
// } from 'bootstrap-vue';
import { CODE_TO_LANG_LUT } from '@copernicsw/community-common';
import vSelect from 'vue-select';

export default {
  name: 'Locale',
  
  components: {
    // BNavItemDropdown,
    // BDropdownItem,
    // BImg,
    // BFormRadioGroup,
    // BFormGroup,
    vSelect,
  },
  props: {
    displayMode: {
      type: String,
      default: 'dropdown',
    },
    hasLabel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedLocale: this.$store.state.locale.currentLocale,
      codeToLangLut: {
        // idiomas actuales
        'en': 'English',
        'es': 'Español',
        'ca': 'Català',
        'fr': 'Français [beta]',
        'it': 'Italiano [beta]',
        'de': 'Deutsch [beta]',
        'pt': 'Português [beta]',
        'bg': 'Български [beta]',
        'da': 'Dansk [beta]',
        'el': 'ελληνικά [beta]',
        'ru': 'Русский [beta]',
        'zh': '中国人 [beta]',
        'ja': '日本語 [beta]',
        'ar': 'عربي [beta]',
        'hi': 'हिंदी [beta]',
      }
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    mainSpace() {
      return this.mainCollective.slug ? this.mainCollective : this.currentCollective;
    },
    currentLocale() {
      const locale = this.$store.state.locale.currentLocale;
      return locale ? this.locales.find((x) => x.value === locale) : {};
    },
    locales() {
      const availableLanguages = this.mainSpace?.availableLanguages;
      if (availableLanguages) {
        const userLanguage = availableLanguages.findIndex((language) => language === this.$store.state.locale.currentLocale);
        if (userLanguage > -1) {
          this.selectedLocale = this.$store.state.locale.currentLocale;
        } else {
          const defaultLanguage = availableLanguages.find((item) => (item === this.mainSpace.languageCode));
          this.selectedLocale = defaultLanguage;
        }
      }
      return availableLanguages || this.defaultLanguages;
    },
    localeNames() {
      const objeto = {};
      this.locales?.forEach((element) => {
        objeto[element] = this.codeToLangLut[element];
      });
      return objeto;
    },
    loggedUserLanguage() {
      return this.$store.getters.loggedUser?.language;
    },
    localeNamesArray() {
      const array = [];
      this.locales?.forEach((locale) => {
        const newLocale = { 
          language: this.codeToLangLut[locale], 
          key: locale 
        };
        array.push(newLocale);
      });
      return array;
    },
    defaultLanguages() {
      return this.$store.getters.locales.map((item) => item.value);
    },
  },
  async created() {
    //
  },
  watch: {
    loggedUserLanguage(value) {
      return value;
    },
  },
  methods: {
    setLocale(newLocale) {
      this.$store.dispatch('setLocale', newLocale);
      this.$store.dispatch('updateUserLocale', { languageCode: newLocale });
    },
  },
};
</script>
